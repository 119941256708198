<div *ngIf="isLoading"  class="loader-container">
    <div class="loader"></div>
  </div>

<div class="container" >
    <div *ngIf="isError" class="error">
        An error occurred. Please try again later.
      </div>
    
</div>
<div class="container" *ngIf="!isLoading && !isError" id="invoice_table">
  
    <div class="header">
      <div class="logo-container">
        <div class="logo">LOGO</div>
        <div class="firm-name">
          {{ invoice.firmName }}<br>
          Legal Services
        </div>
      </div>
      <div class="invoice-number">
        <strong>INVOICE #:</strong> {{ invoice.caseInfo.caseNumber }}
      </div>
    </div>

    <div class="invoice-details">
      <div class="client-info">
        <div class="section-title">Client Information</div>
        <p>
          <strong>Name:</strong> {{ invoice.clientInfo.name }}<br>
          <strong>Address:</strong><br>
          {{ invoice.clientInfo.address.address }}<br>
          {{ invoice.clientInfo.address.city }}, {{ invoice.clientInfo.address.state }} {{ invoice.clientInfo.address.zip }}<br>
      
        </p>
      </div>

      <div class="case-info">
        <div class="section-title">Case Details</div>
        <p>
          
          <strong>Case Name:</strong> {{ invoice.caseInfo.caseName }}<br>
          <strong>File No:</strong> {{ invoice.caseInfo.fileNumber }}<br>
          <strong>Date:</strong> {{ invoice.caseInfo.date }}
        </p>
      </div>
    </div>
 
    <table>
      <thead>
        <tr>
          <th>Date</th>
          <th>Description</th>
          <th>Hours</th>
          <th>Rate</th>
          <th>Amount</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of invoice.invoiceItems">
          <td>{{ item.date | date }}</td>
          <td>{{ item.description }}</td>
          <td>{{ item.hours }}</td>
          <td>{{ item.rate | currency }}</td>
          <td>{{ item.amount | currency }}</td>
        </tr>
        <tr class="total-row">
          <td colspan="4" style="text-align: right;">Total:</td>
          <td>{{ invoice.totalAmount | currency }}</td>
        </tr>
      </tbody>
    </table>
    <div  class="download-btn" id="downloadButton">
      <button (click)="downloadAsPDF()">Download</button>
    </div> 
  </div>