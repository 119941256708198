import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(
    private http: HttpClient
  ) { }

  isDevEnv(env: string) {
    return env.toLowerCase() === 'Development'.toLowerCase() || env.toLowerCase() == 'dev' || env.toLowerCase() == 'staging' || env.toLowerCase() == 'staging' || env.toLowerCase() == 'stg' || env.toLowerCase() == 'stg' || env.toLowerCase() == 'qa' || env.toLowerCase() == 'qa';
  }

  getData(recordId: string, env: string) {
    let baseUrl = '';
    if (this.isDevEnv(env)) {
      baseUrl = environment.baseUrl;
    } else {
      baseUrl = environment.prodUrl;
    }

    return this.http.get(baseUrl+'/record-summary/'+ recordId, {
      headers: {
        'Authorization': 'Bearer ' + sessionStorage.getItem('accessToken')
      }
    });
  }

  getInvoice(caseId: string, env: string) {

    let baseUrl = '';
    if (this.isDevEnv(env) ) {
      baseUrl = environment.baseUrl;
    } else {

      baseUrl = environment.prodUrl;
      console.log(environment.prodUrl);

    }


    return this.http.post(baseUrl+'/invoices/generate',
      {
        caseId
      },
      {
      headers: {
        'Authorization': 'Bearer ' + sessionStorage.getItem('accessToken')
      },
      
    });
  }

}
