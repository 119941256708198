import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MedicalSummaryFormComponent } from './components/medical-summary-form/medical-summary-form.component';
import { AppComponent } from './app.component';
import { InvoicesComponent } from './components/invoices/invoices.component';

const routes: Routes = [
  
  {
    path: 'medicalSummary',
    component: MedicalSummaryFormComponent
   },
   {
    path: 'customerInvoice',
    component: InvoicesComponent
   }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
