import { Component, OnInit } from '@angular/core';
import { AppService } from './app.service';
import { SummaryModel } from 'src/model/summary_response.model';
import { ActivatedRoute } from '@angular/router';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent  {


}
