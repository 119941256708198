import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { SummaryModel } from 'src/model/summary_response.model';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
class pdfRowColumn {
  heading: string;
  info: string;
}

@Component({
  selector: 'app-medical-summary-form',
  templateUrl: './medical-summary-form.component.html',
  styleUrls: ['./medical-summary-form.component.scss']
})
export class MedicalSummaryFormComponent implements OnInit {

  constructor(
    private appService: AppService,
    private router: ActivatedRoute,
    private route: Router
  ){

  }
  pdfRows: pdfRowColumn[] = [];
  isError: boolean = false;
  isLoading: boolean = false;
  summaryModel: SummaryModel;
  accessToken: string;
  ngOnInit(): void {
    this.isLoading = true;

    let recordId = '';
    this.router.queryParams.subscribe(res=>{
      recordId=res['recordId'];
      const env = res['env'];
      const accessToken = res['accessToken'];
      if(accessToken)
      sessionStorage.setItem('accessToken',accessToken)
      // Remove accessToken from URL
      this.route.navigate(['.'], { relativeTo: this.router, queryParams: {
        env,
        recordId
      } });
      
      this.appService.getData(recordId,env).subscribe(res => {
        this.summaryModel = res as any;
        this.pdfRows = [
          {
            heading: 'Full name',
            info: this.summaryModel.summaryResponse.client.firstName + ' '+ this.summaryModel.summaryResponse.client.lastName
          },
          {
            heading: 'Date of birth',
            info: this.summaryModel.summaryResponse.client.birthday
          },
          {
            heading: 'Total cost',
            info: this.summaryModel.summaryResponse.cost
          },
          {
            heading: 'Insurance info',
            info: this.summaryModel.summaryResponse.insuranceInformation
          }
        ];
        if (this.summaryModel.summaryResponse.injuries.length > 0) {
          this.pdfRows.push({
            heading: 'Medical summaries',
            info: 'Following are summaries for patient`s medical reports.'
          })
        }
        this.summaryModel.summaryResponse.injuries.forEach(item => {
          this.pdfRows.push({
            heading: item.title,
            info: item.summary
          })
        })
        this.isLoading = false;
    
      }, err => {
        console.log('Line something',err);
        this.isLoading = false;
        this.isError = true;
      })
    })

  }

  downloadAsPDF = ( ) => {
    const data = document.getElementById('record-summary');
    if (data) {
      html2canvas(data).then(canvas => {
        const imgWidth = 208;
        const imgHeight = canvas.height * imgWidth / canvas.width;
        const contentDataURL = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const position = 0;
        pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
        const pdfName = `${this.summaryModel.summaryResponse.client.firstName}-${this.summaryModel.summaryResponse.client.lastName}_${new Date().toISOString()}.pdf`
        pdf.save(pdfName);
      });
    }
  }


}
