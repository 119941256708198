import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { InvoiceResponse } from 'src/model/invoice_model';

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';



@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss']
})
export class InvoicesComponent implements OnInit {

  constructor(
    private appService: AppService,
    private router: ActivatedRoute,
    private route: Router
  ) { }
  accessToken: string;

  isError: boolean = false;
  isLoading: boolean = false;
  invoice: InvoiceResponse;

  ngOnInit(): void {
    this.isLoading = true;

    let caseId = '';
    this.router.queryParams.subscribe(res=>{
      caseId=res['caseId'];
      const env = res['env'];
      const accessToken = res['accessToken'];
      if(accessToken)
      sessionStorage.setItem('accessToken',accessToken)
      // Remove accessToken from URL
      this.route.navigate(['.'], { relativeTo: this.router, queryParams: {
        env,
        caseId
      } });
      
      this.appService.getInvoice(caseId,env).subscribe(res => {
        this.invoice = res as InvoiceResponse;
        this.isLoading = false;
    
      }, err => {
        console.log('Line something',err);
        this.isLoading = false;
        this.isError = true;
      })
    })
  }

  downloadAsPDF = ( ) => {
    const downloadButton = document.getElementById('downloadButton');
    downloadButton.style.display = 'none';
    const data = document.getElementById('invoice_table');

    if (data) {
      console.log(this.invoice);
      html2canvas(data).then(canvas => {
        const imgWidth = 208;
        const imgHeight = canvas.height * imgWidth / canvas.width;
        const contentDataURL = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const position = 0;
        pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
        const pdfName = `${this.invoice?.clientInfo?.name ?? ''}-${this.invoice.caseInfo.caseName ?? ''}_${new Date().toISOString()}.pdf`
        pdf.save(pdfName);
        downloadButton.style.display = 'flex';
      }).catch(err => {
        downloadButton.style.display = 'flex';
      });
    }
  }

}



