<div class="container">
    <div *ngIf="isLoading" class="loader-container">
        <div class="loader"></div>
      </div>
    
      <!-- Error State -->
      <div *ngIf="isError" class="error">
        An error occurred. Please try again later.
      </div>
    <div *ngIf="!isLoading && !isError" class="download-btn">
      <button (click)="downloadAsPDF()">Download</button>
    </div> 
    <div *ngIf="!isLoading && !isError" id="record-summary">
        <div class="row" *ngFor="let item of pdfRows">
            <div class="column heading">
              {{ item.heading }}
            </div>
            <div class="column info">
              {{ item.info }}
            </div>
          </div>
    </div>
 
  </div>
  
